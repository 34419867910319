import { graphql } from 'gatsby';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import Color from 'color';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Grid from '../components/grid';
import Box from '../components/box';
import Content from '../components/content.js';
import Card from '../components/card';
import TitleCardsPill from '../components/title-cards-pill';
import SharePanel from '../components/share-panel';
import IntroTextWrapper from '../components/intro-text-wrapper';
import VariableFontTitle from '../components/variable-font-title';
import Subscribe from '../components/subscribe';
import PagePaddingTop from '../components/page-padding-top';
import HorizontalRule from '../components/horizontal-rule';
import { goodColors } from '../components/colors';

export default function Page({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/');
  }, []);

  return (
    <PagePaddingTop
    >
      <SEO
        title={data.page.title}
      />
      <Box x="10" y="6">
        <Grid
          css={css`
            max-width: 1440px;
            padding-bottom: var(--margin8);
            margin: 0 auto;
          `}
          columns={8}
        >
          <h4
            css={css`
              text-align: center;
              margin-bottom: var(--margin10);
            `}
            className="type--underline type--heading-four"
          >{data.page.title}</h4>
          <BlockContent
            css={css`
              h4 {
                font-size: inherit;
              }
            `}
            className="type--paragraph"
            content={data.page._rawText}
          />
        </Grid>
      </Box>
    </PagePaddingTop>
  );
}

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      id
      title
      _rawText(resolveReferences: { maxDepth: 10 })
    }
  }
`;
